import React, { useState, useRef, useEffect, useContext } from "react";

import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import {
    CssBaseline,
    Switch,
    FormGroup,
    FormControlLabel,
} from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

const useStyles = makeStyles((theme) => ({
    icons: {
        color: "#fff",
    },
    switch: {
        color: "#fff",
    },
    visible: {
        display: "none",
    },
    btnHeader: {
        color: "#fff",
    },
}));

const DarkMode = (props) => {
    const classes = useStyles();

    const [theme, setTheme] = useState("light");

    const [checked, setChecked] = React.useState(false);

    const themeToggle = () => {
        theme === "light" ? setTheme("dark") : setTheme("light");
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (checked === false) {
            props.themeToggle();
            themeToggle();
        } else if (checked === true) {
            props.themeToggle();
            themeToggle();
        }
    };

    return (
        <>
            <CssBaseline />
            <FormGroup row className={classes.switch}>
                <FormControlLabel
                    control={
                        <Switch
                            className={classes.visible}
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                    label={
                        theme === "light" ? (
                            <Brightness4Icon className={classes.btnHeader} />
                        ) : (
                            <Brightness7Icon className={classes.btnHeader} />
                        )
                    }
                />
            </FormGroup>
        </>
    );
};

export default DarkMode;

import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import {
    AddCircleOutline,
    ChatBubbleOutlineOutlined,
    LibraryBooks,
} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EventIcon from "@material-ui/icons/Event";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.secondary.main,
    },
}));

function ListItemLink(props) {
    const { icon, primary, to, className } = props;
    const classes = useStyles();

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <li>
            <ListItem button component={renderLink} className={className}>
                {icon ? (
                    <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                ) : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const MainListItems = (props) => {
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user } = useContext(AuthContext);
    const [connectionWarning, setConnectionWarning] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    return (
        <div onClick={drawerClose}>
            <ListItemLink
                to="/"
                primary="Dashboard"
                icon={<DashboardOutlinedIcon />}
            />
            <ListItemLink
                to="/Painel01"
                primary="Gráficos"
                icon={<DashboardOutlinedIcon />}
            />            
            <ListItemLink
                to="/connections"
                primary={i18n.t("mainDrawer.listItems.connections")}
                icon={
                    <Badge
                        overlap="rectangular"
                        badgeContent={connectionWarning ? "!" : 0}
                        color="error"
                    >
                        <SyncAltIcon />
                    </Badge>
                }
            />
            <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsAppIcon />}
            />

            <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlinedIcon />}
            />
            <ListItemLink
                to="/quickAnswers"
                primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                icon={<QuestionAnswerOutlinedIcon />}
            />
            <ListItemLink
                to="/schedules"
                primary={i18n.t("mainDrawer.listItems.schedules")}
                icon={<EventIcon />}
            />
            <ListItemLink
                to="/tags"
                primary={i18n.t("mainDrawer.listItems.tags")}
                icon={<LocalOfferIcon />}
            />
            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <>
                        <Divider />
                        <ListSubheader inset>Gerenciar Campanhas</ListSubheader>

                        <ListItemLink
                            to="/BulkMessage"
                            primary="Campanhas"
                            icon={<ChatBubbleOutlineOutlined />}
                        />
                        <ListItemLink
                            to="/ShippingReport"
                            primary="Relatório Envios"
                            icon={<LibraryBooks />}
                        />
                        <ListItemLink
                            to="/SettingsMessage"
                            primary="Config. Envios"
                            icon={<AddCircleOutline />}
                        />
                        <Divider />
                        <ListSubheader inset>
                            {i18n.t("mainDrawer.listItems.administration")}
                        </ListSubheader>
                        <ListItemLink
                            to="/users"
                            primary={i18n.t("mainDrawer.listItems.users")}
                            icon={<PeopleAltOutlinedIcon />}
                        />
                        <ListItemLink
                            to="/queues"
                            primary={i18n.t("mainDrawer.listItems.queues")}
                            icon={<AccountTreeOutlinedIcon />}
                        />

                        <ListItemLink
                            to="/settings"
                            primary={i18n.t("mainDrawer.listItems.settings")}
                            icon={<SettingsOutlinedIcon />}
                        />
                        <Divider />
                        <ListSubheader inset>
                            {i18n.t("mainDrawer.listItems.api")}
                        </ListSubheader>

                        <ListItemLink
                            to="/tokens"
                            primary={i18n.t("mainDrawer.listItems.tokens")}
                            icon={<VpnKeyIcon />}
                        />

                        <ListItemLink
                            to="/docs"
                            primary={i18n.t("mainDrawer.listItems.docs")}
                            icon={<MenuBookIcon />}
                        />
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;

import React, { useEffect } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	iframeDashboard: {
        width: "100%",
        height: "calc(100vh - 64px)",
        border: "none",
    },
}))


const Painel01 = () => {
	const classes = useStyles();

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
		return () => {
			socket.disconnect();
		};
	}, []);

	return (
		<div className={classes.root}> 
            <iframe
                src="https://metabase.chatserver.app/public/dashboard/5fea23c9-7861-4fcf-bd84-aa7f8a344862"
                 className={classes.iframeDashboard}
                 allowtransparency={"true"}
            ></iframe>
		</div>
	);
};

export default Painel01;